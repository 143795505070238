// src/middleware/authMiddleware.ts
import { Middleware, MiddlewareAPI,Dispatch } from 'redux';
import { RootState } from './rootReducer';
import { AppDispatch } from './store';
import {logout } from '../actions/authActions';


// Middleware to check authentication
const authMiddleware: Middleware<{}, RootState> = (store: MiddlewareAPI<AppDispatch, RootState>) => next => (action: any)  => {
  // Only check for specific API actions
   if (action.type.startsWith('API_')) {
     let state:RootState=store.getState();
     if(state.auth.tokendetails && state.auth.tokendetails.expiryDate && state.auth.tokendetails.expiryDate>new Date()){     
      store.dispatch(logout(state.auth.tokendetails?.userId));
      return;
     }
     else{
      return next(action); 
     }     
    
  } 
   
  return next(action); // Pass action to the next middleware or reducer
};

export default authMiddleware;
