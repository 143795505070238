import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { isAxiosError } from './typeGuards';

// Base API service class
class BaseApiService {
  protected api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Request interceptor
    this.api.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    // Response interceptor
    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: unknown) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            window.location.href = 'login';
          }
          return Promise.reject(error);
        }
        // Handle non-Axios errors
        return Promise.reject(error);
      }
    );
  }

  // Common request method
  protected async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.api.request<T>(config);
      return response.data;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        throw new Error(error.message || 'API request failed');
      }
      throw new Error('An unexpected error occurred');
    }
  }
}

export default BaseApiService;
