// src/store/reducers/authReducer.ts


import { FAILED, API_PAYMENT_STATUS_REQUEST, SET_PAYMENT_STATUS } from "../actiontypes/userActionTypes";
import { UserState } from "../states/userState";



const initialState: UserState = {
  paymentDetails: null,
  isLoading:false,
  error:null
};
const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case API_PAYMENT_STATUS_REQUEST:
      return { ...state, isLoading: true, error: null };
    case SET_PAYMENT_STATUS:
      return { ...state, paymentDetails: action.payload, isLoading: false, error: null };
    case FAILED:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default userReducer;
