
import { Dispatch } from 'redux';
import loginService from '../http/loginService';
import { AuthActionTypes, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_FAILURE, LOGOUT_SUCCESS } from '../actiontypes/authActionTypes';
import { getTokenDetails } from '../helpers/tokenhelper';
import { AuthState } from '../states/authState';
// Action creators
export const loginRequest = (): AuthActionTypes => ({ type: LOGIN_REQUEST });
export const loginSuccess = (userAuthDetail: AuthState): AuthActionTypes => ({ type: LOGIN_SUCCESS, payload: userAuthDetail });
export const loginFailure = (error: string): AuthActionTypes => ({ type: LOGIN_FAILURE, payload: error });

// Action creators
export const logOutRequest = (): AuthActionTypes => ({ type: LOGOUT_REQUEST });
export const logOutSuccess = (): AuthActionTypes => ({ type: LOGOUT_SUCCESS });
export const logOutFailure = (error: string): AuthActionTypes => ({ type: LOGOUT_FAILURE, payload: error });

// Thunk action creator
export const loginUser = (username: string, password: string) => async (dispatch: Dispatch<AuthActionTypes>) => {
  dispatch(loginRequest());
  try {
    const response = await loginService.login(username, password);
    if(response.token.length>0 && response.message==null){
    getTokenDetails(response.token);
    localStorage.setItem('token', response.token);
    dispatch(loginSuccess({token:response.token,tokendetails:getTokenDetails(response.token),isLoading:false,error:""}));
    }
    else{
      dispatch(loginFailure('username or password wrong'));
    }
  } catch (error) {
    dispatch(loginFailure('username or password wrong'));
  }
};
export const logout = (userid?: number) => async (dispatch: Dispatch<AuthActionTypes>) => {
  dispatch(logOutRequest());
  try {
    //loginService.logout(userid);    
    localStorage.removeItem('token');
    dispatch(logOutRequest());

  } catch (error) {
    dispatch(logOutFailure("Failed to logout"));
  }
};


