
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Peer, { Instance } from "simple-peer"
import io, { Socket } from "socket.io-client"
import "./VideoHome.css"
import axios from 'axios';
import { API_URL } from "../../config"
import { IonContent, IonGrid, IonRow, IonCol, IonButton, IonList, IonItem, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react"

interface CallData {
  from: string;
  name: string;
  signal: any;
}
const socket: Socket = io(API_URL);
interface ContainerProps { }

const VideoHome: React.FC<ContainerProps> = () => {
  const [me, setMe] = useState<string>("");
  const [stream, setStream] = useState<MediaStream | undefined>();
  const [receivingCall, setReceivingCall] = useState<boolean>(false);
  const [caller, setCaller] = useState<string>("");
  const [callerSignal, setCallerSignal] = useState<any>();
  const [callAccepted, setCallAccepted] = useState<boolean>(false);
  const [idToCall, setIdToCall] = useState<string|any>("");
  const [callEnded, setCallEnded] = useState<boolean>(false);
  const [name, setName] = useState<string>("Parent");
  const myVideo = useRef<HTMLVideoElement>(null);
  const userVideo = useRef<HTMLVideoElement>(null);
  const connectionRef = useRef<Instance | null>(null);
  let mediaStream: MediaStream | null = null;

	useEffect(() => {
    
		startCamera();

	socket.on("me", (id) => {
			setMe(id)
		})

		socket.on("callUser", (data:CallData) => {
			setReceivingCall(true)
			setCaller(data.from)
			setName(data.name)
			setCallerSignal(data.signal)
		})
	}, [])

	const callUser = (id:string) => {
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("callUser", {
				userToCall: id,
				signalData: data,
				from: me,
				name: name
			})
		})
		peer.on("stream", (stream) => {
			if(userVideo.current)
				userVideo.current.srcObject = stream
			
		})
		socket.on("callAccepted", (signal) => {
			setCallAccepted(true)
			peer.signal(signal)
		})

		connectionRef.current = peer
	}

	const answerCall =() =>  {
		setCallAccepted(true)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("answerCall", { signal: data, to: caller })
		})
		peer.on("stream", (stream) => {
      if(userVideo.current)
			userVideo.current.srcObject = stream
		})

		peer.signal(callerSignal)
		connectionRef.current = peer
	}

	const leaveCall = () => {
		setCallEnded(true);
    if(connectionRef.current)
		connectionRef.current.destroy()
	}

	const startCamera=async () => {
    try {
      mediaStream = await navigator.mediaDevices.getUserMedia({ video: true,audio:true });
      setStream(mediaStream);
      if (myVideo.current) {
        myVideo.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  }
    const stopCamera = () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
        if (myVideo.current) {
          myVideo.current.srcObject = null;
        }
      }
    }
    return (
    <IonContent className="ion-padding">    
      
      
    <IonGrid>
<IonRow>
   <IonCol className="ion-align-self-start">
          <video playsInline muted ref={myVideo}  className="video-stream" autoPlay  >
          <source  type="video/mp4"></source></video>
   </IonCol>
   {callAccepted && !callEnded ? (
   <IonCol className="ion-align-self-end">
          <video playsInline  ref={userVideo} autoPlay className="video-stream "><source  type="video/mp4"></source></video>
       </ IonCol>
   ):[]}
       </IonRow>
       {callAccepted && !callEnded ? (
       <IonRow>
       <IonCol>
       <div className="ion-text-center">
       <IonButton 
          color="danger"  
          onClick={leaveCall}         
        >
          End Call
        </IonButton>
        </div>
       </IonCol>
       </IonRow>
       ):null}
         {receivingCall && !callAccepted ? ( 
        <IonRow>
       <IonCol>
       <div className="ion-text-center">
     <h1>  Parent is calling... </h1>
       <IonButton 
          color="primary"  
          onClick={answerCall}              
        >
           Answer
        </IonButton>
        </div>
       </IonCol>
       </IonRow>):null}
       </IonGrid>
  
      
       <IonList>
    <IonItem >
      <IonSelect label="Select Caller type" value={name}
      onIonChange={(e) =>setName(e.detail.value)}>
        <IonSelectOption value="Parent">Parent</IonSelectOption>
        <IonSelectOption value="Baby">Baby</IonSelectOption>
      </IonSelect>
    </IonItem>
    {name=="Baby"?
    (<IonItem>
    <CopyToClipboard text={me}>
      <IonButton color="primary" >
        Copy Baby  ID
      </IonButton>
    </CopyToClipboard>
  </IonItem>)
  :
  (<><IonItem>
    <IonTextarea
      id="filled-basic"
      label="Enter Baby ID"
      value={idToCall}
      onIonInput={(e) => setIdToCall(e.detail.value || '')}
    />
    </IonItem>
  <IonItem>  <IonButton color="primary"
   onClick={() => callUser(idToCall)} >
       Call Baby
      </IonButton></IonItem>
  </>)}
    </IonList>
       
     
    </IonContent>
	)
}

export default VideoHome
