// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonInput, IonButton, IonLabel, IonItem, IonToast, IonLoading, useIonRouter, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { loginUser } from '../actions/authActions';
import { getUserDetails } from '../actions/userActions';
import './Login.css'
const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const dispatch = useAppDispatch();   
     const router = useIonRouter();  
    const authstate = useAppSelector((state) => state.auth);
    const userstate = useAppSelector((state) => state.user);

    const handleLogin = () => {
        if (!username || !password) {
            setToastMessage('Please enter both username and password.');
            setShowToast(true);
            return;
        }

        dispatch(loginUser(username, password));
    };

    useEffect(() => {
      if (userstate.paymentDetails!=null) {
        if(userstate.paymentDetails.paymentStatus==true){
        router.push('/home', 'root', 'replace');
        }
        else{
            router.push('/payment', 'root', 'replace');
        }
      }
    }, [userstate.paymentDetails, router]);
    useEffect(() => {
        if (authstate.tokendetails?.userId!=null&&authstate.tokendetails?.userId!=0) {
          dispatch(getUserDetails(authstate.tokendetails.userId));
        }
      }, [authstate.tokendetails?.userId]);
    useEffect(() => {
        if (authstate.error) {
            setToastMessage(authstate.error);
            setShowToast(true);
        }
    }, [authstate.error]);
    useEffect(() => {
        if (userstate.error) {
            setToastMessage(userstate.error);
            setShowToast(true);
        }
    }, [userstate.error]);

    return (
        <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Baby Phone</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="padding-top-20vh">  
                <IonItem>
                    <IonLabel position="floating">Username</IonLabel>
                    <IonInput
                        value={username}
                        onIonInput={(e: any) => setUsername(e.target.value)}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                        type="password"
                        value={password}
                        onIonInput={(e: any) => setPassword(e.target.value)}
                    />
                </IonItem>
                <IonButton expand="full" className='margin-20' onClick={handleLogin} disabled={authstate.isLoading}>
                    {authstate.isLoading||userstate.isLoading ? 'Logging in...' : 'Login'}
                </IonButton>
                <IonLoading isOpen={authstate.isLoading||userstate.isLoading} message="Please wait..." />
                <IonToast
                    isOpen={showToast}
                    message={toastMessage}
                    duration={3000}
                    onDidDismiss={() => setShowToast(false)}
                />
            </IonContent>
        </IonPage>
    );
};

export default Login;

