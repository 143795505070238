import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact, useIonRouter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
import '@ionic/react/css/palettes/dark.system.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Payment from './pages/Payment';
import { useAppSelector } from './common/hooks';
import { useEffect } from 'react';

setupIonicReact();

const App: React.FC = () =>{
  const router = useIonRouter();  
  const authstate = useAppSelector((state) => state.auth);
  useEffect(() => {
    
      if(authstate.LogOut){
      router.push('/login', 'root', 'replace');
      }
  }, [authstate.LogOut, router]);
  return (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route  path="/login">
          <Login />
        </Route>
        <Route  path="/home">
          <Home />
        </Route>
        <Route  path="/payment">
          <Payment />
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);
}

export default App;
