import { combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import userReducer from '../reducers/userReducer';


// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  user:userReducer,
  // Add other reducers here
});

// Export the combined reducer
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
