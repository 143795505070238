import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Import your rootReducer
import authMiddleware from './authMiddleware';

// Create the store with the root reducer and default middleware
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(authMiddleware),
});

// Export types for use in components and hooks
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export default store;
