import { API_URL, VERSION_URL } from '../config';
import BaseApiService from './baseApiService';

// Define types for user data
interface User {
  paymentStatus: boolean;
  paymentExpiryDate:Date;
}

interface UpdateUserData {
  name?: string;
  email?: string;
}

// User service class
class UserService extends BaseApiService {
  constructor() {
    super(API_URL+VERSION_URL);
  }

  // Fetch user data
  public async getUserPaymentStatus(userid:number): Promise<User> {
    return this.request<User>({
      method: 'GET',
      url: 'getPaymentStatus/'+userid,
    });
  }

  // Update user data
  public async updateUserData(data: UpdateUserData): Promise<User> {
    return this.request<User>({
      method: 'PUT',
      url: '/user',
      data,
    });
  }
}

export default new UserService();
