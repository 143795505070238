

import { useEffect, useState } from 'react';
import './Payment.css'
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from "@ionic/react"
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { getUserDetails } from '../actions/userActions';

const Payment: React.FC = () => {
    const [isPaymentclicked, setPaymentClick] = useState<boolean>(false); 
    const router = useIonRouter();  
   const authstate = useAppSelector((state) => state.auth);
   const userstate = useAppSelector((state) => state.user);

    const dispatch = useAppDispatch();   
    const handlepayment=()=>{
        setPaymentClick(true);
        window.open('https://babyphone.pro')
    }
    const refreshPage=()=>{
        setPaymentClick(false);
        if(authstate.tokendetails){
        dispatch(getUserDetails(authstate.tokendetails.userId));
        }
        else{
            router.push('/login', 'root', 'replace'); 
        }

    }
    useEffect(() => {
        if (userstate.paymentDetails!=null) {
          if(userstate.paymentDetails.paymentStatus==true){
          router.push('/home', 'root', 'replace');
          }
          else{
            setPaymentClick(false);
          }
        }
      }, [userstate.paymentDetails, router]);
     
    return (
        <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Baby Phone</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="padding-top-20vh">  
             <div> {isPaymentclicked?"Have you finished the payment? click the refresh button to continue.":"Please make the payment to continue."}</div>
                <IonButton expand="full" className='margin-20' onClick={isPaymentclicked?refreshPage:handlepayment} >
                {isPaymentclicked?"Refresh":"Payment"}
                </IonButton>
                
            </IonContent>
        </IonPage>
	)
}

export default Payment
