import * as JWT from 'jwt-decode';

export function getTokenDetails(token: string):TokenDetails|null {
    try {
      const decoded: TokenDetails =  JWT.jwtDecode<TokenDetails>(token);
      return decoded;
    } catch (error) {
      console.error('Invalid token or unable to decode', error);
      return null;
    }
  }