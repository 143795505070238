import { API_URL, VERSION_URL } from '../config';
import BaseApiService from './baseApiService';

// Define types for authentication responses
interface LoginResponse {
  token: string;
  message:string;
}

class loginService extends BaseApiService {
  constructor() {
    super(API_URL+VERSION_URL);
  }

  // Log in method
  public async login(username: string, password: string): Promise<LoginResponse> {
    return this.request<LoginResponse>({
      method: 'POST',
      url: 'login',
      data: { username, password },
    });
  }

  // Log out method
  public async logout(userid:number): Promise<void> {
    return this.request<void>({
      method: 'POST',
      url: 'logout',
    });
  }
}

export default new loginService();
