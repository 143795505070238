// src/store/reducers/authReducer.ts

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS } from "../actiontypes/authActionTypes";
import { AuthState } from "../states/authState";

const initialState: AuthState = {
  token: null,
  isLoading: false,
  error: null,
  tokendetails:{userId:0},
  LogOut:null
};
const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true, error: null,LogOut:null };
    case LOGIN_SUCCESS:
      return { ...state, token: action.payload.token
        ,tokendetails: action.payload.tokendetails, isLoading: action.payload.isLoading, error: action.payload.error,LogOut:false };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload, isLoading: false,LogOut:null };
    case LOGOUT_SUCCESS:
      return { ...state, token: null
        ,tokendetails: {userId:0}, error: action.payload, isLoading: false,LogOut:true };
    default:
      return state;
  }
};

export default authReducer;
