import { AuthState } from "../states/authState";

// src/store/actions/actionTypes.ts
export const API_PAYMENT_STATUS_REQUEST = 'API_PAYMENT_STATUS_REQUEST';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const FAILED = 'FAILED';

// Define types for action creators
interface RequestPaymentStatusAction {
  type: typeof API_PAYMENT_STATUS_REQUEST;
}

interface SetPaymentStatusAction {
  type: typeof SET_PAYMENT_STATUS;
  payload: paymentdetails; 
}

interface FailureAction {
  type: typeof FAILED;
  payload: string; // Error message
}

// Union action types
export type UserActionTypes = RequestPaymentStatusAction|SetPaymentStatusAction|FailureAction;
