import { AuthState } from "../states/authState";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_FAILURE = 'LOGIN_FAILURE';
// Define types for action creators
interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: AuthState; // Token
}

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  payload: string; // Error message
}

interface LogOutRequestAction {
  type: typeof LOGOUT_REQUEST;
}

interface LogOutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

interface LogOutFailureAction {
  type: typeof LOGOUT_FAILURE;
  payload: string; // Error message
}

// Union action types
export type AuthActionTypes = LoginRequestAction | LoginSuccessAction | LoginFailureAction|LogOutRequestAction|LogOutSuccessAction|LogOutFailureAction;
