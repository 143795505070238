
import { Dispatch } from 'redux';
import { FAILED, API_PAYMENT_STATUS_REQUEST, SET_PAYMENT_STATUS, UserActionTypes } from '../actiontypes/userActionTypes';
import userService from '../http/userService';

// Action creators
export const paymentDetailRequest = (): UserActionTypes => ({ type: API_PAYMENT_STATUS_REQUEST });
export const setUserPaymentDetails = (paymentdetails:paymentdetails): UserActionTypes => ({ type: SET_PAYMENT_STATUS, payload: paymentdetails });
export const FailuredUserActions = (error: string): UserActionTypes => ({ type: FAILED, payload: error });

// Thunk action creator
export const getUserDetails = (userid: number) => async (dispatch: Dispatch<UserActionTypes>) => {
  dispatch(paymentDetailRequest());
  try {
    const response = await userService.getUserPaymentStatus(userid);
    
    dispatch(setUserPaymentDetails({paymentStatus:response.paymentStatus,paymentExpiryDate:response.paymentExpiryDate}));
   
  } catch (error) {
    dispatch(FailuredUserActions("Something went wrong"));
  }
};


